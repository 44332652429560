const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/Toronto' }

export const formattedDate = (date, customOptions = options) => {
  customOptions.timeZone = 'America/Toronto'
  return new Intl.DateTimeFormat('fr-CA', customOptions).format(new Date(date))
}

export const getHours = (date, separator = 'H') => {
  return formattedDate(date, { hour: '2-digit', minute: '2-digit', timeZone: 'America/Toronto' }).replace(' h ', separator)
}

export const getHoursWith2023Format = (date) => {
  const formattedTime = formattedDate(date, { hour: '2-digit', minute: '2-digit', timeZone: 'America/Toronto' }).replace(':', 'h')
  return formattedTime.replace('h00', 'h')
}
