import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import SingleAppearance from '../components/Appearances/SingleAppearance'

const Event = ({ pageContext }) => {
  let {
    title,
    description,
    stage,
    main_image: mainImage,
    start_time: startDate,
    lpdv_id: lpdvID,
    free: isFree
  } = pageContext.post.data

  title = title?.text
  description = description?.text
  lpdvID = lpdvID?.text

  const isActivite = (pageContext?.appearance?.data?.artist?.document == null) // If there is no artist, it's an activity
  const stageName = stage?.document?.data?.title?.text
  const stageSlug = stage?.document?.slug
  const appearance = pageContext.appearance
  const pageTitle = 'Évènements'
  return (
    <Wolflayout title={pageTitle}>
      <FestifHelmet htmlAttributes={{ lang: 'fr' }}>
        <script src="https://lepointdevente.com/plugins/cart/js?locale=fr_CA&amp;ga=UA-32197713-2"></script>
      </FestifHelmet>
      <Banner title={pageTitle} />
      <div className="w-full py-0 px-8 lg:px-16">
        <section className="artiste-page">
          <p className="font-openSans text-light text-white text-center my-4 w-full">Ce spectacle fait aussi partie de</p>
          <div className="flex justify-center mb-12">

            {/* GRATUIT */}
            <div className={'text-center'}>
              {isFree
                ? (
                <span className={'text-lg uppercase font-openSans !text-extrabold text-white font-bold py-2 px-4 rounded-2xl inline-block mr-2 bg-blue-wolf'}>
                  Gratuit
                </span>
                  )
                : null}
            </div>

            {/* ACTIVITÉ */}
            <div className={'text-center'}>
              {isActivite
                ? (
                <span className={'text-lg uppercase font-openSans !text-extrabold text-white font-bold py-2 px-4 rounded-2xl inline-block mr-2 bg-blue-wolf'}>
                  Activité
                </span>
                  )
                : null}
            </div>

          </div>

          {/* NOM DE L'ÉVÈNEMENT */}
          <h2 className={'uppercase font-ohno text-[65px] text-white text-center leading-none tracking-wider my-8'}>
            {title}
          </h2>
          <br />

          {/* PHOTO DE L'ÉVÈNEMENT */}
          <div className="flex justify-around">
            <div className="lg:w-2/3 mr-1 relative text-center">
              {mainImage
                ? (
                <GatsbyImage
                  className="w-3/5 h-full max-w-[500px]"
                  image={getImage(mainImage)}
                  alt={mainImage.alt ? mainImage.alt : ''}
                  imgClassName="rounded-3xl"
                />
                  )
                : null}
            </div>
          </div>
        </section>

        {/* DESCRIPTION */}
        { description
          ? <div className='text-center text-white my-6 mx-64 text-lg leading-6 font-openSans font-bold tracking-wide'>
              <h1>{description}</h1>
            </div>
          : null }

        {/* APPEARANCES */}
        { appearance
          ? <section className="py-4 flex flex-col md:flex-row flex-wrap text-center md:!max-w-[60%] max-w-full mx-auto px-auto">
                <SingleAppearance title={title} isFree={isFree} lpdvID={lpdvID} time={startDate} stageName={stageName} stageSlug={stageSlug}/>
            </section>
          : null }

        <div className="h-12"></div>
      </div>
    </Wolflayout>
  )
}

export default Event
