import React from 'react'
import SectionTitle from '../Ui/SectionTitle'
import { getHoursWith2023Format } from '../../utils/formattedDate'
import getTicketURL from '../../utils/TicketURL'

const Appearances = ({ title, isFree, lpdvID, time: startDate, stageName, stageSlug }) => {
  const getFormattedDayOfWeek = () => {
    const dateTime = new Date(startDate)

    //  Day of week
    const date = dateTime.getHours() < 4 ? dateTime.getDate() - 1 : dateTime.getDate()
    dateTime.setDate(date)
    const dayOfWeek = dateTime.toLocaleString('fr-CA', { weekday: 'long' })
    const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)

    // Day in month
    const dayInMonth = dateTime.getDate()

    // Month
    const month = dateTime.toLocaleString('fr-CA', { month: 'long' })
    const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1)

    // Year
    // let formattedYear = dateTime.getFullYear();
    return formattedDayOfWeek + ' ' + dayInMonth + ' ' + formattedMonth// + " " + formattedYear;
  }

  return (
    <div className='md:w-1/2 w-full mb-2 md:max-w-[50%] max-w-full text-center mx-auto'>
      <SectionTitle className={'font-poppins font-black text-white tracking-wide text-center text-3xl uppercase mb-4'}>
        {getFormattedDayOfWeek()}
      </SectionTitle>
      <div className="font-poppins font-semibold text-white tracking-wide text-2xl uppercase">
        {title
          ? (
          <a href="/infos/carte#info_content" >
            {title}
          </a>
            )
          : null}
      </div>
      <div className="font-poppins font-black text-white text-lg lg:text-2xl text-center">
        <span className="lowercase">
            {getHoursWith2023Format(startDate)}
            {' - '}
        </span>
        <span>
            <a href={stageSlug ? `/scene/${stageSlug}` : '/infos/carte#info_content'}>
                {stageName}
            </a>
        </span>
      </div>
      <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} my-12`}>
        {
          (lpdvID && lpdvID !== '')
            ? <div>
                <a href={`https://lepointdevente.com/tickets/${lpdvID}`}
                className="font-poppins font-extrabold text-white text-3xl uppercase px-12 py-4 !bg-purple-wolf hover:!bg-white/20 rounded-3xl">
                {'Réserver'}
                </a>
            </div>
            : isFree
              ? ''
              : <div>
                <a href={getTicketURL()}
                className="font-poppins font-extrabold text-white text-3xl uppercase px-12 py-4 !bg-purple-wolf hover:!bg-white/20 rounded-3xl">
                {'Billets'}
                </a>
            </div>
        }
      </div>
    </div>
  )
}

export default Appearances
