import React from "react";

const SectionTitle = ({ children, className }) => {
    return (
        <h2 className={` ${className}`}>
            {children}
        </h2>
    );
};

export default SectionTitle;
